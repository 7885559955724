.app-generated-content {
	padding: $t-a-space-absolute-400;
	background-color: $t-a-palette-default-blue-lighter;
	font-family: $t-a-typography-font-family-default;
	margin: 0 -16px $t-a-space-absolute-1200 -16px;
	@include media-query('lg') {
		border-radius: $t-a-border-radius-200;
		margin: 0 0 $t-a-space-absolute-1200 0;
		padding-bottom: $t-a-typography-font-size-l;
	}
}

.app-generated-content__header {
	display: flex;
	gap: $t-a-space-absolute-100;
	align-items: center;
	margin: 0 0 $t-a-space-absolute-200 0;
}

.app-generated-content__heading {
	font-size: $t-a-typography-font-size-l;
	font-weight: $t-a-typography-heading-font-weight;
	margin: 0;
}

.app-generated-content__summary {
	line-height: $t-a-typography-font-size-l;
	margin-bottom: $t-a-space-absolute-200;
	font-size: $t-a-typography-font-size-s;
	@include media-query('lg') {
		font-size: $t-a-typography-font-size-default;
		line-height: $t-a-typography-font-size-xl;
	}
}

.app-generated-content > .app-generated-content__disclaimer {
	margin-bottom: 0;
	font-size: $t-a-typography-font-size-xs;
	color: $t-a-text-secondary;
}

.app-generated-content__icon {
	@include u-icon(36px, 36px);
}
